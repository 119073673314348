import { useCallback, FC } from 'react'
import { Box, Button } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useNavigate } from 'react-router'

const useStyles = makeStyles()((theme) => ({
  actionsContainer: {
    display: 'flex',
    gap: theme.spacing(3)
  },
  button: {
    textTransform: 'capitalize'
  }
}))

export interface AlertActionProps {
  onReset?: () => void
  onRetry: () => void
}

const AlertActions: FC<AlertActionProps> = ({ onReset, onRetry }) => {
  const navigate = useNavigate()

  const onResetHandler = useCallback(() => {
    if (onReset) {
      onReset()
      return
    }
    navigate(0)
  }, [navigate, onReset])

  const { classes } = useStyles()

  return (
    <Box className={classes.actionsContainer}>
      <Button onClick={onResetHandler} variant='text' className={classes.button}>
        Home
      </Button>
      <Button onClick={onRetry} variant='text' className={classes.button}>
        Retry
      </Button>
    </Box>
  )
}

export { AlertActions }
