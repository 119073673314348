import { AxiosError } from 'axios';

export enum AlertActionTypes {
  onSetAlert = 'Alert/@@/onSetAlert',
  onClearAlert = 'Alert/@@/onClearAlert',
}

export interface Actions {
  onRetry: () => void;
  onReset?: () => void;
}

export interface DefaultState {
  shouldShowAlert: boolean;
  onSetAlert: (actions: Actions, error: any) => void;
  onClearAlert: () => void;
  onReset: () => void;
  actions: null | Actions;
  info: null | Error | AxiosError;
}

export interface AlertReducerAction {
  type: AlertActionTypes;
  payload: {
    actions: null | Actions;
    info: null | Error | AxiosError;
  };
}

export type AlertReducer = (state: DefaultState, action: AlertReducerAction) => DefaultState;
