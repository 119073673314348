const getOperatingSystem = () => {
  const isAndroid = /Android/i.test(navigator.userAgent);
  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

  return {
    isIOS,
    isAndroid,
  };
};

export { getOperatingSystem };
