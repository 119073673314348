import React, { useState } from 'react'
import { AddTipButton } from './styled'
import { InputAdornment, Box, TextField, IconButton } from '@mui/material'
import { Close as CloseIcon, Done as DoneIcon } from '@mui/icons-material'
import { makeStyles } from 'tss-react/mui'
import { robinPayPaletteColors } from '../theme'
import { useValidation } from '../hooks'
import { useAnalytics } from '../contexts/AnalyticsProvider/hooks'

export enum AddTipButtonSteps {
  INITIAL = 'INITIAL',
  ADD_AMOUNT = 'ADD_AMOUNT'
}

const useStyles = makeStyles()((theme) => ({
  buttonsContainer: {
    display: 'flex',
    gap: theme.spacing(2)
  },
  doneIcon: { color: robinPayPaletteColors.navyColor },
  closeIcon: { color: robinPayPaletteColors.pinkRed }
}))

export interface AddATipProps {
  externalTipValue: string
  setter: React.Dispatch<React.SetStateAction<string>>
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
}

const AddATip = ({ setter, externalTipValue, setIsEditing }: AddATipProps) => {
  const { event } = useAnalytics()
  const [value, setValue] = useState({ tip: '' })
  const [step, setStep] = useState(AddTipButtonSteps.INITIAL)

  const externalValidators = {
    tip: {
      isValid: (value: string) => /^\d*?[.]?\d*$/.test(value),
      message: 'Tip must be a number, decimals are allowed. For decimal notation please use .'
    }
  }

  const { validateValues, errorMessages, ifError, onChange, setValidationMessages, setClientValidationErrors, resetValidation } =
    useValidation(['tip'], externalValidators)

  const onValidatorChangeHandler = (e: any) => {
    onChange(e, setValue)
    event({ action: 'Web-add-tip-input-change', category: 'Web-user-actions', label: 'Web-Adding-Tip-Amount', nonInteraction: false })
  }

  const onKeyPress = (e: any) => {
    const currentValue = e.target.value

    if (e.key === '0' && currentValue === '0') {
      setValidationMessages({ [e.target.name]: 'After 0 please use . to denote decimal or provide another number' })
      setClientValidationErrors({ [e.target.name]: true })
      e.target.value = 0
      e.preventDefault()
      return
    }

    const validFractionDigitPointer =
      /[.]/.test(e.key) &&
      !currentValue.includes('.') &&
      ((currentValue.includes('-') && currentValue.length > 1) || (!currentValue.includes('-') && currentValue.length > 0))

    const validKey = /(\d|\w)/.test(e.key) || validFractionDigitPointer

    if ((currentValue === '0' && !validFractionDigitPointer) || !validKey) {
      setValidationMessages({
        [e.target.name]: 'Only . is allowed after 0, following a number to denote decimal amount'
      })
      setClientValidationErrors({ [e.target.name]: true })
      e.preventDefault()
    }
  }

  const onClickAddTipButton = () => {
    event({ action: 'Web-add-tip', category: 'user-actions', label: 'Web-Add-Tip-Button-Clicked', nonInteraction: false })
    setIsEditing(true)
    setStep(AddTipButtonSteps.ADD_AMOUNT)
  }

  const onClickRemoveTip = () => {
    event({ action: 'Web-remove-tip', category: 'user-actions', label: 'Web-Remove-Tip-Button-Clicked', nonInteraction: false })
    setIsEditing(false)
    setter('')
    setValue({ tip: '' })
  }

  const onClose = () => {
    event({ action: 'Web-add-tip-X-icon', category: 'user-actions', label: 'Web-Rejected-Tip-Amount', nonInteraction: false })
    setValue({ tip: '' })
    setStep(AddTipButtonSteps.INITIAL)
    resetValidation()
    setIsEditing(false)
  }

  const onClickDone = () => {
    if (value.tip === '0') {
      setValidationMessages({
        tip: 'Zero amount is not accepted. Please provide another amount.'
      })
      setClientValidationErrors({ tip: true })
      return
    }
    if (value.tip.indexOf('0') === 0 && value.tip.indexOf('.') !== 1) {
      setValidationMessages({
        tip: 'Only . is allowed after 0, following a number to denote decimal amount'
      })
      setClientValidationErrors({ tip: true })
      return
    }

    if (!validateValues(value)) {
      return
    }
    event({ action: 'Web-add-tip-Tick-icon', category: 'user-actions', label: 'Web-Confirmed-Typed-Tip', nonInteraction: false })
    setter(value.tip)
    setStep(AddTipButtonSteps.INITIAL)
    setValue({ tip: '' })
    setIsEditing(false)
  }

  const isTipError = ifError('tip')
  const tipHelperText = errorMessages('tip')
  const isTextFieldTipTickButtonDisabled = value.tip.length === 0 || isTipError

  const { classes } = useStyles()

  if (step === AddTipButtonSteps.ADD_AMOUNT) {
    return (
      <TextField
        id='tipAmountField'
        value={value.tip}
        onChange={onValidatorChangeHandler}
        onKeyPress={onKeyPress}
        error={isTipError}
        helperText={tipHelperText}
        name='tip'
        type='text'
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <Box className={classes.buttonsContainer}>
                <IconButton onClick={onClickDone} disabled={isTextFieldTipTickButtonDisabled} edge='end'>
                  <DoneIcon className={classes.doneIcon} />
                </IconButton>
                <IconButton onClick={onClose} edge='end'>
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              </Box>
            </InputAdornment>
          )
        }}
      />
    )
  }

  if (externalTipValue) {
    return <AddTipButton onClick={onClickRemoveTip}>Remove tip</AddTipButton>
  }

  return <AddTipButton onClick={onClickAddTipButton}>Add a tip</AddTipButton>
}

export { AddATip }
