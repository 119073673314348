import { ErrorBoundary } from 'react-error-boundary';
import { FC, PropsWithChildren } from 'react';
import { ErrorFallbackComponent } from './ErrorCallbackComponent';
import { websiteUrl } from '../../constants';

const GlobalErrorBoundary: FC<PropsWithChildren<{}>> = ({ children }) => {
  const onReset = () => window.location.replace(websiteUrl);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallbackComponent} onReset={onReset}>
      {children}
    </ErrorBoundary>
  );
};

export { GlobalErrorBoundary };
