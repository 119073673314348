import { styled } from '@mui/system'
import { robinPayPaletteColors } from '../../theme'

const SaveAmountHeartImage = styled('img')({
  width: '1.4rem',
  height: '1.4rem',
  marginRight: '4px'
})

const AddTipButton = styled('button')((theme) => ({
  fontSize: '1.3rem',
  fontFamily: 'ProximaNovaBold',
  background: robinPayPaletteColors.whiteColor,
  color: robinPayPaletteColors.blueDarkColor,
  border: 'none',
  textTransform: 'uppercase',
  '&:hover': {
    cursor: 'pointer'
  }
}))

export { SaveAmountHeartImage, AddTipButton }
