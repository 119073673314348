import React from 'react'
import { AnalyticsContext } from './AnalyticsContext'
import { defaultState } from './defaultState'
import ReactGA from 'react-ga4';

export interface AnalyticsProviderProps { }

ReactGA.initialize('G-Z3PVH5XKK4')

const AnalyticsProvider: React.FC<React.PropsWithChildren<AnalyticsProviderProps>> = ({ children }) => {
  return (
    <AnalyticsContext.Provider value={defaultState}>
      {children}
    </AnalyticsContext.Provider>
  )
}

export { AnalyticsProvider }
