import { useContext } from 'react';
import { AnalyticsContext } from './AnalyticsContext';

const useAnalytics = () => {
  const { pageView, event } = useContext(AnalyticsContext);

  return { pageView, event };
};

export { useAnalytics };
