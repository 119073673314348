import { styled } from '@mui/system'
import { Typography, Grid, IconButton } from '@mui/material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import React from 'react'
import { robinPayPaletteColors } from '../theme'

const AccountCardContainer = styled('div')((theme) => ({
  width: '100%',
  height: '100%',
  maxHeight: '8.3rem',
  backgroundColor: robinPayPaletteColors.darkLightGreyColor,
  borderRadius: '8px',
  padding: '1.3rem 2rem 1rem'
}))

const AccountImg = styled('img')((theme) => ({
  width: '2rem',
  height: '2rem',
  borderRadius: '8px'
}))

const AccountCard = ({ accountImg, accountName, iban }: { accountImg?: string; accountName: string; iban: string }) => {
  return (
    <AccountCardContainer>
      <Grid container>
        <Grid item xs={10}>
          {accountImg && <AccountImg src={accountImg} alt='account-image' />}
          <Typography sx={{ fontSize: '1.1rem', fontFamily: 'ProximaNovaBold', color: robinPayPaletteColors.navyColor }}>
            {accountName}
          </Typography>
          <Typography sx={{ fontSize: '0.75rem', fontFamily: 'ProximaNova', color: robinPayPaletteColors.battleshipGrey }}>
            {iban}
          </Typography>
        </Grid>
        <Grid container item xs={2} justifyContent='center' alignItems='center'>
          <IconButton sx={{ width: '2rem', height: '2rem' }}>
            <EditOutlinedIcon />
          </IconButton>
        </Grid>
      </Grid>
    </AccountCardContainer>
  )
}

export { AccountCard }
