import * as React from 'react';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  TextField,
  AlertTitle,
  Typography,
  CircularProgress,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import SuccessIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { useValidation } from '../hooks';
import { useAnalytics } from '../contexts/AnalyticsProvider/hooks';
import { makeStyles } from 'tss-react/mui';
import CloseIcon from '@mui/icons-material/Close';
import { robinPayPaletteColors } from '../theme';
import { postInvoice } from '../axios/post';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '../types';
import { LayoutBox } from './LayoutBox';

const useStyles = makeStyles()(theme => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: '1.2rem',
    fontFamily: 'ProximaNovaSemiBold',
    color: robinPayPaletteColors.navyColor,
  },
  cancelBtn: {
    fontSize: '1rem',
    fontFamily: 'ProximaNovaSemiBold',
    color: robinPayPaletteColors.pinkRed,
    textTransform: 'none',
  },
  sendInvoiceBtn: {
    fontSize: '1rem',
    fontFamily: 'ProximaNovaSemiBold',
    color: robinPayPaletteColors.navyColor,
    textTransform: 'none',
  },
  dialogActions: {
    padding: `${theme.spacing(2)} !important`,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  dialogPaper: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  retryBtn: {
    textTransform: 'none',
    fontSize: '1rem',
    fontFamily: 'ProximaNovaSemiBold',
    color: robinPayPaletteColors.pinkRed,
  },
  emailSentText: {
    fontSize: '1.2rem',
    fontFamily: 'ProximaNovaSemiBold',
    color: robinPayPaletteColors.navyColor,
  },
  icon: {
    width: '100px',
    height: '100px',
    color: robinPayPaletteColors.navyColor,
  },
  spinner: {
    color: robinPayPaletteColors.navyColor,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const SendReceiptDialog = ({
  isOpen,
  onToggleDialog,
  webPaymentSessionId,
}: {
  isOpen: boolean;
  onToggleDialog: () => void;
  webPaymentSessionId?: string;
}) => {
  const { event } = useAnalytics();
  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState('');
  const queryClient = useQueryClient();

  const { data, isLoading, mutate } = useMutation({
    mutationFn: postInvoice,
    mutationKey: [QUERY_KEYS.POST_INVOICE],
    useErrorBoundary: false,
  });

  const { validateValues, errorMessages, ifError, resetValidation } = useValidation(['email']);

  const onSendInvoice = () => {
    if (!validateValues({ email })) {
      return;
    }
    if (!webPaymentSessionId) {
      return;
    }
    event({
      action: 'Web-send-receipt',
      category: 'user-actions',
      label: `Web-Clicked-Send-Receipt-From-Dialog`,
      nonInteraction: false,
    });
    mutate(
      {
        email,
        webPaymentSessionId,
      },
      {
        onSuccess: () => {
          event({
            action: 'Web-send-receipt-success',
            category: 'Network',
            label: 'Web-Receipt-Request-Sent',
            nonInteraction: true,
          });
        },
        onError: (error: any) => {
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.POST_INVOICE] });
          setError(error?.response?.data?.code || error.message);
        },
      },
    );
  };

  const onChange = (e: any) => {
    resetValidation();
    setEmail(e.target.value);
    event({
      action: 'Web-input-change_email',
      category: 'user-actions',
      label: 'Web-Typing-Email',
      nonInteraction: false,
    });
  };

  const onToggleDialogHandler = () => {
    if (error) {
      setError('');
    }
    resetValidation();
    setEmail('');
    onToggleDialog();
  };

  const isEmailError = ifError('email');
  const emailHelperText = errorMessages('email');
  const isButtonDisabled = email.length === 0 || isEmailError || isLoading;

  const { classes } = useStyles();

  return (
    <Dialog
      open={isOpen}
      classes={{ paper: classes.dialogPaper }}
      TransitionComponent={Transition}
      keepMounted
      onClose={!isLoading ? onToggleDialogHandler : () => {}}
      aria-describedby='alert-dialog-slide-description'>
      <DialogTitle className={classes.dialogTitle}>
        <span className={classes.title}>Send Receipt</span>
        <IconButton
          edge='end'
          sx={{ color: robinPayPaletteColors.navyColor }}
          onClick={onToggleDialog}
          disabled={isLoading}
          aria-label='close'>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {isLoading && (
          <LayoutBox padding={4}>
            <CircularProgress className={classes.spinner} />
          </LayoutBox>
        )}
        {error && !isLoading && (
          <Alert
            severity='error'
            action={
              <Button className={classes.retryBtn} disabled={isLoading} onClick={onSendInvoice}>
                Retry
              </Button>
            }>
            <AlertTitle>Error</AlertTitle>
            <strong>{error}</strong>
          </Alert>
        )}
        {!error && data && !isLoading && (
          <LayoutBox flexDirection='column' alignItems='center' gap={2}>
            <SuccessIcon className={classes.icon} />
            <Typography className={classes.emailSentText}>{data}</Typography>
          </LayoutBox>
        )}
        {!error && !data && !isLoading && (
          <TextField
            name='email'
            onChange={onChange}
            value={email}
            label='Email'
            error={isEmailError}
            helperText={emailHelperText}
            disabled={isLoading}
            fullWidth
          />
        )}
      </DialogContent>
      {!data && (
        <DialogActions className={classes.dialogActions}>
          {!error && (
            <Button
              fullWidth
              size='small'
              onClick={onSendInvoice}
              className={classes.sendInvoiceBtn}
              disabled={isButtonDisabled}>
              Send Receipt
            </Button>
          )}
          <Button
            fullWidth
            size='small'
            onClick={onToggleDialogHandler}
            className={classes.cancelBtn}
            disabled={isLoading}>
            Cancel
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export { SendReceiptDialog };
