import { rest } from 'msw';
import webPaymentsDetails from './mockData/web.payments.details.json';
import webPaymentsStepB from './mockData/web.payments.stepB.json';
import webPaymentsFinalize from './mockData/web.payments.finalize.json';
import providersData from './mockData/providers.json';

const errorMessage = {
  description:
    'The payment request could not be fulfilled due to insufficient funds. We can help you on https://docs.yapily.com/support',
};

export const handlers = [
  rest.post('/web/payments/details', (req, res, ctx) => {
    return res(ctx.delay(2000), ctx.json(webPaymentsDetails));
  }),
  rest.post('/web/payments/step-b', (req, res, ctx) => {
    return res(ctx.delay(2000), ctx.json(webPaymentsStepB));
  }),
  rest.post('/web/payments/finalize', (req, res, ctx) => {
    return res(ctx.delay(2000), ctx.json(webPaymentsFinalize));
  }),
  rest.get('/web/providers', (req, res, ctx) => {
    return res(ctx.delay(2000), ctx.json(providersData));
  }),
  rest.post('/web/payments/send-invoice', (req, res, ctx) => {
    return res(ctx.delay(2000), ctx.json({}));
  }),
];
