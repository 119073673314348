import ReactGA from 'react-ga4';

const defaultState = {
  pageView: (data: { hitType: string; page: string }) => ReactGA.send(data),
  event: (
    options: {
      action: string;
      category: string;
      label?: string;
      value?: number;
      nonInteraction?: boolean;
      transport?: 'beacon' | 'xhr' | 'image';
    },
    params?: any,
  ) => ReactGA.event(options, params),
};

export { defaultState };
