import { Nullable } from '../types'

const formatAmount = (value: number, currency: string, minimumFractionDigits = 2, numberFormat?: Nullable<string>): string => {
  const formatterOptions = {
    minimumFractionDigits
  } as Intl.NumberFormatOptions

  if (!!currency) {
    formatterOptions.style = 'currency'
    formatterOptions.currency = currency
  } else {
    formatterOptions.style = 'decimal'
  }

  const defaultLocale = 'en'
  const valueFormatter = new Intl.NumberFormat(numberFormat ?? defaultLocale, formatterOptions)

  return valueFormatter.format(value).replace(/^(\D+)/, '$1 ')
}

export { formatAmount }
