const getCookie = (cName: string) => {
  const name = cName + '=';
  const cArr = document.cookie.split('; ');
  let res;
  cArr.forEach(val => {
    if (val.indexOf(name) === 0) {
      res = val.substring(name.length);
    }
  });
  return res;
};

export { getCookie };
