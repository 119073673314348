import React, { FC, PropsWithChildren } from 'react'
import { Box, BoxProps } from '@mui/material'

const LayoutBox: FC<PropsWithChildren<BoxProps>> = ({ children, sx, ...restProps }) => {
  return (
    <Box display='flex' justifyContent='center' {...restProps}>
      {children}
    </Box>
  )
}

export { LayoutBox }
