import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { brandConfig } from './pageConfigs';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { AxiosInterceptor, GlobalBoundaryWrapper } from './components';
import { theme } from './theme';
import { AnalyticsProvider } from './contexts';
import { PageLoader } from './components';

const ConfirmPaymentFlowPage = React.lazy(() => import('./pages/ConfirmPaymentFlowPage'));
const PaymentFlowFinalizePage = React.lazy(() => import('./pages/PaymentFlowFinalizePage'));
const RedirectPage = React.lazy(() => import('./pages/RedirectPage'));
const ErrorPage = React.lazy(() => import('./pages/ErrorPage'));

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <GlobalBoundaryWrapper>
        <React.Suspense fallback={<PageLoader />}>
          <RedirectPage />
        </React.Suspense>
      </GlobalBoundaryWrapper>
    ),
    errorElement: <ErrorPage />,
    hasErrorBoundary: true,
  },
  {
    path: '/pay',
    element: (
      <GlobalBoundaryWrapper>
        <React.Suspense fallback={<PageLoader />}>
          <ConfirmPaymentFlowPage pageConfig={brandConfig.confirmPaymentFlowPage} />
        </React.Suspense>
      </GlobalBoundaryWrapper>
    ),
    errorElement: <ErrorPage />,
    hasErrorBoundary: true,
  },
  {
    path: '/handler',
    element: (
      <GlobalBoundaryWrapper>
        <React.Suspense fallback={<PageLoader />}>
          <PaymentFlowFinalizePage pageConfig={brandConfig.confirmPaymentFlowPage.finalizePage} />
        </React.Suspense>
      </GlobalBoundaryWrapper>
    ),
    errorElement: <ErrorPage />,
    hasErrorBoundary: true,
  },
  {
    path: '/handler/#code',
    element: (
      <GlobalBoundaryWrapper>
        <React.Suspense fallback={<PageLoader />}>
          <PaymentFlowFinalizePage pageConfig={brandConfig.confirmPaymentFlowPage.finalizePage} />
        </React.Suspense>
      </GlobalBoundaryWrapper>
    ),
    errorElement: <ErrorPage />,
    hasErrorBoundary: true,
  },
  {
    path: '/*',
    element: (
      <GlobalBoundaryWrapper>
        <React.Suspense fallback={<PageLoader />}>
          <RedirectPage />
        </React.Suspense>
      </GlobalBoundaryWrapper>
    ),
    errorElement: <ErrorPage />,
    hasErrorBoundary: true,
  },
]);

const App: React.FC<{ queryClient: QueryClient }> = ({ queryClient }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <AnalyticsProvider>
          <AxiosInterceptor>
            <RouterProvider router={router} />
          </AxiosInterceptor>
        </AnalyticsProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
