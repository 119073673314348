const websiteUrl = 'https://www.robinpayapp.com';
const localhost = 'http://localhost:3000';
const productionBaseUrl = 'https://inqredi-yapily.herokuapp.com';
const stagingBaseUrl = 'https://inqredi-yapily-staging.herokuapp.com';

const APP_LINKS = {
  ANDROID: 'https://play.google.com/store/apps/details?id=com.inqredi.droid.client',
  IOS: 'https://apps.apple.com/gb/app/robinpay/id1446182551',
  UQR_SEXE: 'https://uqr.to/sexe',
};

export { websiteUrl, localhost, productionBaseUrl, stagingBaseUrl, APP_LINKS };
