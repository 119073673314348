import React, { FC, PropsWithChildren } from 'react';
import { Typography, Container, Box, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Helmet } from 'react-helmet';
import { GenericObject } from '../types';
import { makeStyles } from 'tss-react/mui';
import { robinPayPaletteColors } from '../theme';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
    gap: theme.spacing(0.5),
    justifyContent: 'center',
  },
  btn: {
    color: robinPayPaletteColors.navyColor,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
    flex: 1,
  },
  text: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const MainLayout: FC<PropsWithChildren<GenericObject>> = ({ layout, onGoBack, children, externalBtn }) => {
  const { classes } = useStyles();
  return (
    <Box position='relative' width='100%' height='100%' display='flex' flexDirection='column'>
      {layout?.helmetTitle && (
        <Helmet>
          <title>{layout.helmetTitle}</title>
        </Helmet>
      )}
      <Container maxWidth='xs' className={classes.container}>
        {onGoBack ? (
          <Box className={classes.root}>
            <IconButton className={classes.btn} onClick={onGoBack}>
              <ArrowBackIcon />
            </IconButton>
            {layout?.pageTitle && (
              <Typography variant='h1' align='center' sx={layout?.titleSxProps || {}} className={classes.text}>
                {layout.pageTitle}
              </Typography>
            )}
          </Box>
        ) : (
          layout?.pageTitle && (
            <Typography id={layout?.titleId || 'title'} variant='h1' align='center' sx={layout?.titleSxProps || {}}>
              {layout.pageTitle}
            </Typography>
          )
        )}
        {children}
      </Container>
      {externalBtn && <Container maxWidth='xs'>{externalBtn}</Container>}
    </Box>
  );
};

export { MainLayout };
