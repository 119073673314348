import { Typography, Box, Button } from '@mui/material'
import { MainLayout } from '../../layouts'
import { makeStyles } from 'tss-react/mui'
import { useNavigate } from 'react-router'
import { robinPayPaletteColors } from '../../theme'
import React from 'react'
import { useErrorRenderer } from '../../hooks'

const useStyles = makeStyles()((theme) => ({
  actionContainer: {
    display: 'flex',
    gap: theme.spacing(3),
    marginTop: theme.spacing(2)
  },
  buttonText: {
    textTransform: 'capitalize'
  },
  primary: {
    color: robinPayPaletteColors.whiteColor,
    backgroundColor: robinPayPaletteColors.navyColor
  },
  secondary: {
    color: robinPayPaletteColors.whiteColor,
    backgroundColor: robinPayPaletteColors.pinkRed,
    '&: hover': {
      color: robinPayPaletteColors.whiteColor,
      backgroundColor: robinPayPaletteColors.pinkRed
    }
  }
}))

const ErrorFallbackComponent = ({ error, resetErrorBoundary }: { error: any; resetErrorBoundary: any }) => {
  const navigate = useNavigate()
  const onRefreshPage = () => navigate(0)
  const { classes, cx } = useStyles()

  const errorRenderer = useErrorRenderer(error)

  return (
    <MainLayout>
      <Box role='alert' display='flex' flex={1} flexDirection='column' margin='auto' justifyContent='center'>
        <Typography variant='h5' component='h1' gutterBottom>
          Something went wrong
        </Typography>
        {error && (
          <>
            <Typography variant='subtitle1' gutterBottom>
              {error?.message}
            </Typography>
            {error?.response?.data?.code && (
              <Typography variant='subtitle1' gutterBottom>
                {error.response.data.code}
              </Typography>
            )}
            {errorRenderer()}
          </>
        )}
        <Box className={classes.actionContainer}>
          <Button fullWidth variant='contained' onClick={resetErrorBoundary} className={cx(classes.buttonText, classes.primary)}>
            Home
          </Button>
          <Button
            fullWidth
            color='primary'
            variant='contained'
            onClick={onRefreshPage}
            className={cx(classes.buttonText, classes.secondary)}
          >
            Refresh
          </Button>
        </Box>
      </Box>
    </MainLayout>
  )
}

export { ErrorFallbackComponent }
