import { isProductionMode } from './environment';

const initiateMocksWithServiceWorkerByEnv = (predicateFn?: () => boolean) => {
  if ((predicateFn && predicateFn()) || !isProductionMode()) {
    const { worker } = require('../mocks/browser');
    worker.start({
      serviceWorker: {
        url: 'http://localhost:3000/mockServiceWorker.js',
      },
    });
  }
};

export { initiateMocksWithServiceWorkerByEnv };
