import { robinPayPaletteColors } from '../theme';

const brandConfig = {
  referralPage: {
    logo: '',
    icon: '',
  },
  confirmPaymentFlowPage: {
    initializePaymentStep: {
      layout: {
        titleId: 'confirmPayment',
        helmetTitle: 'Confirm payment | robinpay',
        pageTitle: 'Confirm payment',
        titleSxProps: {
          fontSize: '1rem',
          pt: '1.3rem',
          fontFamily: 'ProximaNovaSemiBold',
          lineHeight: '1.2rem',
          color: robinPayPaletteColors.navyColor,
        },
      },
    },
    chooseProviderStep: {
      layout: {
        titleId: 'confirmPayment',
        helmetTitle: 'Confirm payment | robinpay',
        pageTitle: 'Confirm payment',
        titleSxProps: {
          fontSize: '1rem',
          pt: '0rem',
          fontFamily: 'ProximaNovaSemiBold',
          lineHeight: '1.2rem',
          color: robinPayPaletteColors.navyColor,
        },
      },
    },
    finalizePage: {
      layout: {
        titleId: 'paymentStatus',
        helmetTitle: 'Payment Status | robinpay',
        pageTitle: 'Payment Status',
        titleSxProps: {
          fontSize: '1rem',
          pt: '1.3rem',
          fontFamily: 'ProximaNovaSemiBold',
          lineHeight: '1.2rem',
          color: robinPayPaletteColors.navyColor,
        },
      },
    },
  },
};

export { brandConfig };
