import { Typography, TypographyProps } from "@mui/material";

const useErrorRenderer = (error?: any, className?: string, typoProps: TypographyProps = { variant: 'subtitle1', gutterBottom: true }) => {
  return () => {
    if (!error) {
      return null
    }

    if (error && typeof error?.response?.data?.description === 'string') {
      return (
        <Typography {...typoProps} className={className}>
          {error.response.data.description}
        </Typography>
      );
    }

    if (error.response?.data?.description && !error.response?.data?.description.params) {
      return Object.entries(error.response?.data?.description).map((error: any, index: number) => (
        <Typography key={index} {...typoProps} className={className}>
          {error[0]}: {error[1]}
        </Typography>
      ));
    }

    if (!(error.response?.data?.description as any)?.params) {
      return null;
    }

    return Object.entries((error.response?.data?.description as any)?.params as any).map((item: any) => {
      return (
        <Typography key={item[0]} {...typoProps} className={className}>
          {item[1]?.value[0]}
        </Typography>
      );
    });
  }
};

export { useErrorRenderer }
