import axios, { AxiosResponse, AxiosError } from 'axios'
import { useEffect } from 'react'
import { localhost, productionBaseUrl } from '../constants'
import { isProductionMode } from '../utils'

const axiosInstance = axios.create({
  baseURL: isProductionMode() ? productionBaseUrl : localhost
})

const AxiosInterceptor = ({ children }: { children: JSX.Element }) => {
  useEffect(() => {
    const resInterceptor = (response: AxiosResponse<any>) => {
      return response || null
    }
    const errInterceptor = (error: AxiosError<any, any>) => {
      return error
    }
    const interceptor = axiosInstance.interceptors.response.use(resInterceptor, errInterceptor)
    return () => axiosInstance.interceptors.response.eject(interceptor)
  }, [])

  return children
}

export default axiosInstance

export { AxiosInterceptor }
