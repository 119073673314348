import { QueryClient } from '@tanstack/react-query'

const getQueryClientConfig = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: 0,
        staleTime: Infinity,
        useErrorBoundary: false
      },
      mutations: {
        useErrorBoundary: false
      }
    }
  })

  return queryClient
}

export { getQueryClientConfig }
