import { includes, toLower } from 'ramda'
/**
 * Detect if application is loaded as localhost
 */
const isLocalhost = (): boolean => {
  const hostname = toLower(window.location.hostname)
  return Boolean(includes(hostname, 'localhost') || includes(hostname, '.local'))
}

/**
 * Detect if application is loaded from Jest for testing
 */
const isInJestMode = (): boolean => process.env.JEST_WORKER_ID !== undefined

/**
 * Detect if application is loaded from Cypress for testing
 */
const isInCypressMode = (): boolean => (window as any).Cypress !== undefined

/**
 * Detect if application is build mode is production
 */
const isProductionMode = (): boolean => getBuildMode() === 'production'

/**
 * Detect the node env the application is build on
 */
const getBuildMode = (): string => process.env.NODE_ENV || 'development'

/**
 * Get the build version via the build script as defined in package json
 */
const getBuildVersion = (): string => process.env.REACT_APP_VERSION || 'snapshot'

export { isLocalhost, isInJestMode, isInCypressMode, isProductionMode, getBuildVersion, getBuildMode }
