import { Alert, AlertTitle, Collapse } from '@mui/material'
import React from 'react'
import { websiteUrl } from '../../constants'
import { AlertContext } from './AlertContext'
import { alertReducer } from './alertReducer'
import { AlertActions } from './components'
import { defaultState } from './defaultState'
import { Actions, AlertActionTypes } from './types'
import { AxiosError } from 'axios';

export interface AlertProviderProps { }

const AlertProvider: React.FC<React.PropsWithChildren<AlertProviderProps>> = ({ children }) => {
  const [state, dispatch] = React.useReducer(alertReducer, defaultState)

  const onSetAlert = (actions: Actions, error: Error | AxiosError) => {
    dispatch({ type: AlertActionTypes.onSetAlert, payload: { actions, info: error } })
  }

  const onClearAlert = () => {
    dispatch({ type: AlertActionTypes.onSetAlert, payload: { actions: null, info: null } })
  }

  const onReset = () => window.location.replace(websiteUrl)

  const errorRenderer = () => {
    if (state?.info && state?.info?.message && state?.info?.message !== 'undefined') {
      return state.info.message
    }
    return null
  }

  const error = errorRenderer()

  return (
    <AlertContext.Provider value={{ ...state, onSetAlert, onClearAlert, onReset }}>
      <>
        <Collapse in={state.shouldShowAlert} sx={{ position: 'absolute', bottom: 0, left: 0, right: 0, zIndex: 1 }}>
          {state.info && state.actions && (
            <Alert severity='error' action={<AlertActions onReset={state.actions?.onReset || onReset} onRetry={state.actions.onRetry} />}>
              <AlertTitle>Error</AlertTitle>
              {error && <strong>{error}</strong>}
            </Alert>
          )}
        </Collapse>
        {children}
      </>
    </AlertContext.Provider>
  )
}

export { AlertProvider }
