import { AlertActionTypes, AlertReducer } from './types'

const alertReducer: AlertReducer = (state, action) => {
  switch (action.type) {
    case AlertActionTypes.onSetAlert:
      return {
        ...state,
        shouldShowAlert: true,
        ...action.payload
      }
    case AlertActionTypes.onClearAlert:
      return {
        ...state,
        shouldShowAlert: false,
        ...action.payload
      }
    default:
      return state
  }
}

export { alertReducer }
