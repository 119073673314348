import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { getQueryClientConfig, initiateMocksWithServiceWorkerByEnv } from './utils'

initiateMocksWithServiceWorkerByEnv()
const queryClient = getQueryClientConfig()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <App queryClient={queryClient} />
)
