import { useEffect } from 'react';

const useSessionStorage = (key: string, value: any) => {
  useEffect(() => {
    const handleUnload = (e: any) => {
      window.sessionStorage.setItem(key, value);
    };
    window.addEventListener('unload', handleUnload);
    return () => {
      window.removeEventListener('unload', handleUnload);
    };
  }, [key, value]);
};

export { useSessionStorage };
