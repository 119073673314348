import { Actions, DefaultState } from './types'

const defaultState = {
  shouldShowAlert: false,
  onSetAlert: (actions: Actions, error: any) => {},
  onClearAlert: () => {},
  onReset: () => {},
  actions: null,
  info: null
} as DefaultState

export { defaultState }
