import React from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ArrowForward } from '@mui/icons-material';
import { robinPayPaletteColors } from '../theme';

const useStyles = makeStyles()(theme => ({
  circularProgress: {
    color: robinPayPaletteColors.whiteColor,
    mb: '3px',
    width: '1rem !important',
    height: '1rem !important',
    '& span': {
      width: '1rem',
      height: '1rem',
    },
  },
  arrowForward: {
    marginBottom: '3px',
  },
  button: {
    fontFamily: 'ProximaNovaSemiBold',
    display: 'flex',
    justifyItems: 'center',
    padding: '0 2rem',
    justifyContent: 'space-between',
    color: robinPayPaletteColors.whiteColor,
    backgroundColor: robinPayPaletteColors.navyColor,
    height: '6rem',
    borderRadius: '8px',
    textTransform: 'uppercase',
    '&: hover': {
      color: robinPayPaletteColors.whiteColor,
      backgroundColor: robinPayPaletteColors.navyColor,
    },
    '&: disabled': {
      color: robinPayPaletteColors.whiteColor,
      backgroundColor: robinPayPaletteColors.battleshipGrey,
    },
  },
}));

const NextButton = ({
  isLoading,
  onClick,
  text,
  externalBtnClass,
  isDisabled = false,
  externalCircularProgressClass,
}: {
  isLoading: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  text: string;
  isDisabled?: boolean;
  externalBtnClass?: string;
  externalCircularProgressClass?: string;
}) => {
  const { classes, cx } = useStyles();

  return (
    <Box sx={{ pb: 2.5 }}>
      <Button
        disabled={isLoading || isDisabled}
        onClick={onClick}
        endIcon={
          isLoading ? (
            <CircularProgress className={cx(classes.circularProgress, externalCircularProgressClass)} />
          ) : (
            <ArrowForward className={classes.arrowForward} />
          )
        }
        fullWidth
        classes={{ root: cx(classes.button, externalBtnClass) }}>
        {text}
      </Button>
    </Box>
  );
};

export { NextButton };
