import { createTheme } from '@mui/material/styles';

import ProximaNovaWoff from './assets/fonts/ProximaNova.woff';
import ProximaNovaWoff2 from './assets/fonts/ProximaNova.woff2';
import ProximaNovaSemiBoldWoff from './assets/fonts/ProximaNovaSemiBold.woff';
import ProximaNovaSemiBoldWoff2 from './assets/fonts/ProximaNovaSemiBold.woff2';
import ProximaNovaBoldWoff from './assets/fonts/ProximaNovaBold.woff';
import ProximaNovaBoldWoff2 from './assets/fonts/ProximaNovaBold.woff2';

const robinPayPaletteColors = {
  navyColor: 'rgb(2,38,62)',
  darkGreyColor: '#68757b',
  whiteColor: 'rgb(255, 255, 255)',
  lightGreyColor: 'rgb(102, 119, 128)',
  battleshipGrey: 'rgb(102, 119, 128)',
  rgbaNavyColor: 'rgba(2,38,62,0.8)',
  default: 'rgba(247 , 247 , 247)',
  pinkRed: 'rgb(255,0,76)',
  opalGreyColor: 'rgb(143, 167, 179)',
  blueDarkColor: 'rgb(51, 51, 48)',
  darkLightGreyColor: 'rgb(242, 242, 242)',
};

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(2, 38, 62)',
    },
  },
  typography: {
    fontFamily: 'ProximaNova, ProximaNovaSemiBold, ProximaNovaBold',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html {
          height: 100%;
        }

        body {
          height: 100%;
        }

        #root {
          height: 100%;
        }

        @font-face {
          font-family: 'ProximaNova';
          src: local('ProximaNova'), url(${ProximaNovaWoff}) format('woff');
        }

        @font-face {
          font-family: 'ProximaNova';
          src: local('ProximaNova'), url(${ProximaNovaWoff2}) format('woff2');
        }

        @font-face {
          font-family: 'ProximaNovaSemiBold';
          src: local('ProximaNovaSemiBold'), url(${ProximaNovaSemiBoldWoff}) format('woff');
        }

        @font-face {
          font-family: 'ProximaNovaSemiBold';
          src: local('ProximaNovaSemiBold'), url(${ProximaNovaSemiBoldWoff2}) format('woff2');
        }

        @font-face {
          font-family: 'ProximaNovaBold';
          src: local('ProximaNovaBold'), url(${ProximaNovaBoldWoff}) format('woff');
        }

        @font-face {
          font-family: 'ProximaNovaBold';
          src: local('ProximaNovaBold'), url(${ProximaNovaBoldWoff2}) format('woff2');
        }
        `,
    },
  },
});

export { theme, robinPayPaletteColors };
