function getQueryParams() {
  const results: { key: string; value: string }[] = [];

  let params = window.location.search;

  params = params.replace('?', '');

  const queryParamArray = params.split('&');

  queryParamArray.forEach(function (queryParam) {
    const item = queryParam.split('=');
    results.push({ key: item[0], value: item[1] });
  });

  return results;
}

function getQueryParamsByHashTag() {
  const results: { [props: string]: any } = [];

  let hashParams = window.location.hash;

  if (hashParams) {
    hashParams = hashParams.replace('#', '');

    const queryParamArray = hashParams.split('&');

    queryParamArray.forEach(function (queryParam) {
      const item = queryParam.split('=');
      results[item[0]] = item[1];
    });

    return results;
  } else {
    return getQueryParams();
  }
}

export { getQueryParams, getQueryParamsByHashTag };
