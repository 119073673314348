import React from 'react'

function validateEmail(value: string) {
  return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
    value
  )
}

function validatePhone(value: string) {
  return /^21.\d{7}|^69.\d{7}/.test(value)
}

function validatePassword(value: string) {
  return /^.{5,20}$/.test(value)
}

export default function useValidation(validationSchemaValues: any, externalValidators?: any) {
  const [serverValidationErrors, setServerValidationErrors] = React.useState<any>({})
  const [clientValidationErrors, setClientValidationErrors] = React.useState<any>({})

  const [validationMessages, setValidationMessages] = React.useState<any>({})

  const uiErrors = React.useMemo(() => {
    return !!Object.keys(clientValidationErrors).length
  }, [clientValidationErrors])

  const serverErrors = React.useMemo(() => {
    return !!Object.keys(serverValidationErrors).length
  }, [serverValidationErrors])

  const validateValues = React.useCallback(
    (values: any) => {
      let isValidationError = false
      validationSchemaValues.forEach((schemaValue: string) => {
        if (schemaValue === 'email' && !validateEmail(values[schemaValue])) {
          setClientValidationErrors((values: any) => ({ ...values, email: true }))
          setValidationMessages((values: any) => ({
            ...values,
            email: 'Please type a valid email'
          }))
          isValidationError = true
          return
        }

        if (schemaValue === 'phone' && !validatePhone(values[schemaValue])) {
          setClientValidationErrors((values: any) => ({ ...values, phone: true }))
          setValidationMessages((values: any) => ({
            ...values,
            phone: 'Phone must start with 210 or 69 and must be 10 digits!'
          }))
          isValidationError = true
          return
        }

        if (schemaValue === 'password' && !validatePassword(values[schemaValue])) {
          setClientValidationErrors((values: any) => ({
            ...values,
            password: true
          }))
          setValidationMessages((values: any) => ({
            ...values,
            password: 'Please type your password, from 5 up to 20 digits!'
          }))
          isValidationError = true
          return
        }

        if (!values[schemaValue]) {
          setClientValidationErrors((values: any) => ({
            ...values,
            [schemaValue]: true
          }))
          setValidationMessages((values: any) => ({
            ...values,
            [schemaValue]: 'The field is required!'
          }))
          isValidationError = true
          return
        }
      })

      return !isValidationError
    },
    [setClientValidationErrors, validationSchemaValues]
  )

  const resetValidation = React.useCallback(() => {
    setClientValidationErrors({})
    setServerValidationErrors({})
  }, [])

  const onChange = (e: any, setter: any) => {
    if (!externalValidators[e.target.name]) {
      setter((prev: any) => ({ ...prev, [e.target.name]: e.target.value }))
      return
    }

    const { isValid } = externalValidators[e.target.name]

    if (!isValid(e.target.value)) {
      setValidationMessages((prev: any) => ({
        ...prev,
        [e.target.name]: externalValidators[e.target.name].message
      }))
      setClientValidationErrors((prev: any) => ({ ...prev, [e.target.name]: true }))
      setter((prev: any) => ({ ...prev, [e.target.name]: e.target.value }))
      return
    }

    resetValidation()
    setter((prev: any) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const ifError = React.useCallback(
    (value: any) => {
      if (uiErrors) {
        return clientValidationErrors[value] ? true : false
      }

      if (serverErrors) {
        return serverValidationErrors[value] ? true : false
      }
    },
    [clientValidationErrors, serverValidationErrors, uiErrors, serverErrors]
  )

  const errorMessages = React.useCallback(
    (value: any, customMessage?: any) => {
      if (uiErrors) {
        return clientValidationErrors[value] && customMessage ? customMessage : validationMessages[value]
      }

      if (serverErrors) {
        return serverValidationErrors[value] ? serverValidationErrors[value] : ''
      }
    },
    [clientValidationErrors, serverValidationErrors, uiErrors, serverErrors, validationMessages]
  )

  return {
    errorMessages,
    ifError,
    resetValidation,
    serverValidationErrors,
    setServerValidationErrors,
    validateValues,
    setValidationMessages,
    setClientValidationErrors,
    onChange
  }
}
