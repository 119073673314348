import axiosInstance from '../components/AxiosInterceptor';
import { getQueryParams, getQueryParamsByHashTag } from './helpers';
import { WebPaymentDetails, WebPaymentsFinalize } from './responseTypes';
import { ChooseProviderScreenRequestBody, SendInvoiceRequestBody } from './requestTypes/index';
import { WebPaymentsStepB } from './responseTypes/index';

const postEncryptedUrlForWebPayments = async (): Promise<WebPaymentDetails> => {
  try {
    const { data } = await axiosInstance.post('/web/payments/details', {
      urlParams: getQueryParams(),
    });
    return data;
  } catch (error) {
    throw error;
  }
};

const postSelectedProviderAndPaymentInfo = async (
  requestData: ChooseProviderScreenRequestBody,
): Promise<WebPaymentsStepB> => {
  try {
    const { webPaymentSessionId, obProviderCode, amount } = requestData;
    const { data } = await axiosInstance.post('/web/payments/step-b', { webPaymentSessionId, obProviderCode, amount });

    return data;
  } catch (error: any) {
    throw error;
  }
};

// const postWebPaymentFlowFinalize = async (): Promise<WebPaymentsFinalize> => {
//   try {
//     const paymentFlowStoredData = window.sessionStorage.getItem('webPaymentStepACompleted');

//     const params = getQueryParamsByHashTag();
//     const { data } = await axiosInstance.post('/web/payments/finalize', {
//       params: [
//         { key: 'session_id', value: webPaymentSessionId },
//         { key: 'payment_authorization_id', value: webPaymentSessionId },
//         {
//           key: 'consent',
//           value: params?.consent,
//         },
//         { key: 'application-user-id', value: '5a6e9335a7974b8c92abb9320aa176f6' },
//         { key: 'user-uuid', value: '0b81bef6-9ebe-4a41-9eee-16cb422018d6' },
//         { key: 'institution', value: 'monzo_ob' },
//         { key: 'session_id', value: '53525fd8a0fb4eaaa8f8e4466445dc84' },
//         { key: 'payment_authorization_id', value: '53525fd8a0fb4eaaa8f8e4466445dc84' },
//         { key: 'state', value: '53525fd8a0fb4eaaa8f8e4466445dc84' },
//       ],
//     });

//     return data;
//   } catch (error) {
//     throw error;
//   }
// };

const postInvoice = async (reqBody: SendInvoiceRequestBody): Promise<string> => {
  try {
    const { status } = await axiosInstance.post('/web/payments/send-invoice', reqBody);
    if (status === 200) {
      return 'Receipt Sent';
    } else {
      throw new Error('Receipt not sent');
    }
  } catch (error) {
    throw error;
  }
};

export { postEncryptedUrlForWebPayments, postSelectedProviderAndPaymentInfo, postInvoice };
