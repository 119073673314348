import React from 'react';
import { AlertProvider } from '../contexts';
import { GlobalErrorBoundary } from './globalErrorBoundary';

const GlobalBoundaryWrapper: React.FC<React.PropsWithChildren<{}>> = ({ children }) =>  (
  <GlobalErrorBoundary>
    <AlertProvider>{children}</AlertProvider>
  </GlobalErrorBoundary>
);


export { GlobalBoundaryWrapper }