export type GenericObject<T = any> = {
  [key: string]: T;
};

export type Nullable<T> = T | null;

export type ObjectOfType<T> = {
  [key: string]: T;
};

export enum QUERY_KEYS {
  ENCRYPTED_PAYMENT_URL = 'ENCRYPTED_PAYMENT_URL',
  PROVIDERS = 'PROVIDERS',
  WEB_PAYMENT_STEP_B = 'WEB_PAYMENT_STEP_B',
  WEB_PAYMENT_FINALIZE = 'WEB_PAYMENT_FINALIZE',
  POST_INVOICE = 'POST_INVOICE',
}

export type Provider = {
  code: string;
  name: string;
};
